/* ==========================================================================
	Global
	========================================================================== */

/* V - 10-12-2019 */

body {
  background: url(../images/old_wall.png) repeat center fixed;
  // background-size: cover;
	// background-color: #fff;
  letter-spacing: 0.03rem;
  line-height: 25px;
}

*::selection {
  background-color: #B3D4FB;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {


  small {
    display: block;
    padding-top: 1rem;
  }
}

.page-header-top {
  
  position: absolute;
  bottom: 100%;
  left: 0;
  @include padding(6rem);

  >h1,
  >.h1 {
    margin: 0;
    font-weight: 700;
    color: $brand-primary;
    @include font-size(3.5rem);
    text-transform: uppercase;
  }

  @media(min-width: $screen-md-min) {
    left: 33.3333%;
    width: 100%;
    max-width: 900px;
  }
}

.btn,
a,
button {
  outline: 0 !important;
  transition: all 0.3s;
}

.thumbnail,
.panel {
  border: none;
  box-shadow: $box-shadow;
}

.container-lg {
  @extend .container-fluid;
  max-width: 1400px;
}

.container-max {
  @extend .container-lg;

  @media(min-width: 1500px) {
    max-width: 85%;
  }
}

.container-main {
  @extend .container-max;

  @media(min-width: $screen-sm-min) {
    padding: 0 5% 6%;
  }
}

.no-pad {
  padding: 0;
}

.no-marge {
  margin: 0;
}



/* ==========================================================================
	Headers
	========================================================================== */

// @import "header-basic";
// @import "header-basic-2";
@import "header-slider-1";
// @import "header-slider-2";
// @import "header-slider-3";
// @import "header-slider-4";
// @import "header-slider-5";

/* ==========================================================================
	Navbar Si centrage elements sur deux lignes
	========================================================================== */

.navbar-nav {
  // @extend .navbar-right; // pour aligner � droite.
  text-align: center;

  @media(min-width: $screen-md-min) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    float: none;
  }

  >li {

    >a {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        height: 0;
        background-color: $navbar-default-link-active-color;
        opacity: 0;
        transition: all ease-in-out .3s;
      }
    }

    &:hover>a::after,
    &:active>a::after,
    &:focus>a::after,
    &.active>a::after {
      left: 16px;
      right: 16px;
      height: 3px;
      opacity: 1;
    }
  }
}

.index-nav {
  @extend .pull-right;
  display: inline-block;
  line-height: $navbar-height;
  padding-right: 10px;
  color: $navbar-default-toggle-icon-bar-bg;
  text-transform: uppercase;
  font-weight: 700;

  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}


/* ==========================================================================
	Navbar
	========================================================================== */


.navbar-nav {
  @extend .navbar-right; // pour aligner � droite.

  >li {

    >a {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        height: 0;
        background-color: $brand-primary;
        opacity: 0;
        transition: all ease-in-out .3s;
      }
    }

    &:hover>a::after,
    &:active>a::after,
    &:focus>a::after,
    &.active>a::after {
      left: 1rem;
      right: 1rem;
      height: 3px;
      opacity: 1;
    }
  }
}

.index-nav,
.index-red {
  @extend .pull-right;
  display: inline-block;
  line-height: $navbar-height;
  padding-right: 10px;
  color: $navbar-default-toggle-icon-bar-bg;
  text-transform: uppercase;
  font-weight: 700;

  &--text {
    color: red;
  }

  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

/* ==========================================================================
	Main
	========================================================================== */

.main {
  @extend .clearfix;
  min-height: 50vh;
  position: relative;
}

/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
  @extend .col-md-9;
  @extend .col-md-push-3;
  padding-bottom: 6rem;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
  @extend .col-md-3;
  @extend .col-md-pull-9;
  padding-top: 3rem;
}

/* ==========================================================================
	Footer
	========================================================================== */

.footer {
  background-color: $body-bg;
}

.container-footer {
  @extend .container-max;
  @extend .text-center;
  padding: 5%;

  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
}

.footer-main {
  @extend .clearfix;
}

.footer-address {
  line-height: 1.8;
}

ul.list-footer {
  @extend .list-unstyled;
  margin: 0;

  >li {
    padding: 5px 0;
  }

  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
    margin-top: 20px;
    border-top: solid 1px rgba(black, .2);
  }
}

ul.list-footer-right {
  @extend .list-unstyled;
  margin: 0;

  >li {
    padding: 5px 0;
  }
}

ul.list-logo-footer {
  @extend .list-inline;
  margin-top: 30px;
  padding-top: 30px;
  border-top: solid 1px $hr-border;

  >li {
    padding: 0;
  }

  @media (max-width: $screen-sm-max) {
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
    border-top: solid 1px r$hr-border;
  }

  img {
    height: 37px;
  }
}

.panel-ref-footer {
  @extend .well;
  @include padding(3rem);
  margin: 0;

  h1, h2, h3 {
    color: white;
  }
}

/* ==========================================================================
	Custom
	========================================================================== */


// ******************
// MIXINS
// ******************

@mixin breakpoint($point) {
  @if $point==lg {
    @media only screen and (min-width: $screen-lg-min) {
      @content;
    }
  }

  @else if $point==md {
    @media only screen and (min-width: $screen-md-min) {
      @content;
    }
  }

  @else if $point==sm {
    @media only screen and (min-width: $screen-sm-min) {
      @content;
    }
  }

  @else if $point==xs {
    @media only screen and (max-width: $screen-xs-min) {
      @content;
    }
  }
}

@mixin background($imgpath: "",
  $overlay: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))) {
  background: $overlay, url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

@mixin background2($imgpath: "", $color: rgba(0, 0, 0, 0), $opacity: 0) {
  background: linear-gradient(rgba($color, $opacity), rgba($color, $opacity)),
    url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

//********** FLEXI BOOTSTRAP FROM Brian Willis

@media only screen and (min-width: $screen-md-min) {
  .flex-row.row {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-row.row>[class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  .flex-row.row:after,
  .flex-row.row:before {
    display: flex;
  }
}

// ******************
// GRID
// ******************

// ******************
// COMPONENTS
// ******************

// -- Buttons

.btn-white {
  color: $gray-darker;
  background-color: $gray-lighter;
  border-color: $gray-lighter;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: darken($gray-lighter, 10%);
    border-color: darken($gray-lighter, 10%);
  }
}

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary.btn-outline {
  color: $brand-primary;
}

.btn-success.btn-outline {
  color: $brand-success;
}

.btn-info.btn-outline {
  color: $brand-info;
}

.btn-warning.btn-outline {
  color: $brand-warning;
}

.btn-danger.btn-outline {
  color: $brand-danger;
}

.btn-white.btn-outline {
  color: $gray-lighter;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover,
.btn-white.btn-outline:hover {
  color: #fff;
}

// ******************
// UTILS
// ******************


//-- Fonts


.text-xs {
  @include font-size(0.75rem);
  line-height: 2rem;
}

.text-sm {
  @include font-size(0.875rem);
  line-height: 1.71rem;
}

.text-base {
  @include font-size(1rem);
  line-height: 1.5rem;
}

.text-lg {
  @include font-size(1.125rem);
  line-height: 1.75rem;
}

.text-xl {
  @include font-size(2.375rem);
  line-height: 0.63rem;
}

.text-2xl {
  @include font-size(1.5rem);
  line-height: 2rem;
}

.text-3xl {
  @include font-size(1.875rem);
  line-height: 2.25rem;
}

.text-4xl {
  @include font-size(2.25rem);
  line-height: 2.5rem;
}

.text-5xl {
  @include font-size(3rem);
  line-height: 1;
}

.text-6xl {
  @include font-size(3.75rem);
  line-height: 1;
}

.text-7xl {
  @include font-size(4.5rem);
  line-height: 1;
}

.text-8xl {
  @include font-size(6rem);
  line-height: 1;
}

.text-9xl {
  @include font-size(8rem);
  line-height: 1;
}



h2,
.h2 {
  font-size: 32px;
}

h3,
.h3 {
  font-size: 28px;
}

h4,
.h4 {
  font-size: 24px;
}



h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @media (max-width: $screen-sm-max) {
    font-family: $font-family-base;
    font-weight: 900 !important;
  }
}

.font-light {
  font-weight: 200;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-white {
  color: white;
}

.text-lighter {
  color: $gray-lighter;
}

.text-light {
  color: $gray-light;
}

.text-gray {
  color: $gray;
}

.text-dark {
  color: $gray-dark;
}

.text-darker {
  color: $gray-darker;
}

span.underline {
  background-image: linear-gradient(90deg,
      lighten($brand-primary, 10%),
      lighten($brand-primary, 10%));
  background-repeat: no-repeat;
  background-size: 100% 1rem;
  background-position: 0 115%;
}

//-- Display

.d-flex {
  display: flex;
}

@include breakpoint(xs) {
  .d-xs-flex {
    display: flex;
  }
}

@include breakpoint(sm) {
  .d-sm-flex {
    display: flex;
  }
}

@include breakpoint(md) {
  .d-md-flex {
    display: flex;
  }
}

@include breakpoint(lg) {
  .d-lg-flex {
    display: flex;
  }
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center--sm {
  display: inline;
}

@media only screen and (min-width: $screen-sm-min) {
  .flex-center--sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flex-row-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;

  >img {
    height: 200px;
    margin: 10px;
  }
}

.flex-grid {
  display: flex;
}

.flex-grid .col {
  flex: 1;
}

@media (max-width: $screen-md-min) {
  .flex-grid {
    display: block;

    .col {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}

//-- Positions

.relative {
  position: relative;
}

//-- Spacing
.mt-0 {
  margin-top: 0;
}

.mt-1 {
  @include margin-top(1rem);
}

.mt-2 {
  @include margin-top(2rem);
}

.mt-3 {
  @include margin-top(3rem);
}

.mt-4 {
  @include margin-top(4rem);
}

.mt-5 {
  @include margin-top(5rem);
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  @include margin-bottom(1rem);
}

.mb-2 {
  @include margin-bottom(2rem);
}

.mb-3 {
  @include margin-bottom(3rem);
}

.mb-4 {
  @include margin-bottom(4rem);
}

.mb-5 {
  @include margin-bottom(5rem);
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-1 {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}

.my-2 {
  @include margin-top(2rem);
  @include margin-bottom(2rem);
}

.my-3 {
  @include margin-top(3rem);
  @include margin-bottom(3rem);
}

.my-4 {
  @include margin-top(4rem);
  @include margin-bottom(4rem);
}

.my-5 {
  @include margin-top(5rem);
  @include margin-bottom(5rem);
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-1 {
  @include margin-left(1rem);
  @include margin-right(1rem);
}

.mx-2 {
  @include margin-left(2rem);
  @include margin-right(2rem);
}

.mx-3 {
  @include margin-left(3rem);
  @include margin-right(3rem);
}

.mx-4 {
  @include margin-left(4rem);
  @include margin-right(4rem);
}

.mx-5 {
  @include margin-left(5rem);
  @include margin-right(5rem);
}

.pt-1 {
  @include padding-top(1rem);
}

.pt-2 {
  @include padding-top(2rem);
}

.pt-3 {
  @include padding-top(3rem);
}

.pt-4 {
  @include padding-top(4rem);
}

.pt-5 {
  @include padding-top(5rem);
}

.pb-1 {
  @include padding-bottom(1rem);
}

.pb-2 {
  @include padding-bottom(2rem);
}

.pb-3 {
  @include padding-bottom(3rem);
}

.pb-4 {
  @include padding-bottom(4rem);
}

.pb-5 {
  @include padding-bottom(5rem);
}

.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.py-1 {
  @include padding-top(1rem);
  @include padding-bottom(1rem);
}

.py-2 {
  @include padding-top(2rem);
  @include padding-bottom(2rem);
}

.py-3 {
  @include padding-top(3rem);
  @include padding-bottom(3rem);
}

.py-4 {
  @include padding-top(4rem);
  @include padding-bottom(4rem);
}

.py-5 {
  @include padding-top(5rem);
  @include padding-bottom(5rem);
}

.py-8 {
  @include padding-top(8rem);
  @include padding-bottom(8rem);
}

.py-10 {
  @include padding-top(10rem);
  @include padding-bottom(10rem);
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  @include padding-left(1rem);
  @include padding-right(1rem);
}

.px-2 {
  @include padding-left(2rem);
  @include padding-right(2rem);
}

.px-3 {
  @include padding-left(3rem);
  @include padding-right(3rem);
}

.px-4 {
  @include padding-left(4rem);
  @include padding-right(4rem);
}

.px-5 {
  @include padding-left(5rem);
  @include padding-right(5rem);
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  @include margin-right(1rem);
}

.mr-2 {
  @include margin-right(2rem);
}

.mr-3 {
  @include margin-right(3rem);
}

.ml-1 {
  @include margin-left(1rem);
}

.ml-2 {
  @include margin-left(2rem);
}

.ml-3 {
  @include margin-left(3rem);
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

//-- Effects

.shadow-1 {
  box-shadow: 10px 10px 0 0 $brand-primary;
}

.shadow-2 {
  box-shadow: 1rem 2rem 4rem -2rem rgba(black, 0.9);
}

// see : https://codepen.io/iamvdo/pen/lGvCo
.drop-shadow {
  filter: drop-shadow(16px 16px 20px rgba(black, 0.3));
}

//-- Backgrounds

.bg-1 {
  @include background2("https://picsum.photos/id/1018/1920/1280", #000, 0.3);
}

.bg-gradient-1 {
  @include background("",
    linear-gradient(45deg, $brand-primary, $brand-success));
}

.bg-marielouise {
  @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
  border: 3vw solid white;
}

.bg-white {
  background-color: white;
}

.bg-lighter {
  background-color: $gray-lighter;
}

.bg-light {
  background-color: $gray-light;
}

.bg-gray {
  background-color: $gray;
}

.bg-dark {
  background-color: $gray-dark;
}

.bg-darker {
  background-color: $gray-darker;
}

.bg-danger {
  background-color: $brand-danger !important;
}

.bg-success {
  background-color: $brand-success;
}

.bg-primary {
  background-color: $brand-primary;
}

.bg-primary-2 {
  background-color: rgba($brand-primary, 0.7);
}

.bg-primary-offset {
  position: relative;

  &:before {
    content: "";
    background-color: $brand-primary;
    position: absolute;
    display: block;
    width: 100%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//-- Width utils

.w-100 {
  width: 100%;
}

.max-w-15 {
  max-width: 15rem;
}

//-- Height utils
.h-18 {
  height: 18rem;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

//-- Others

.object-cover {
  object-fit: cover;
}

/* image symetry */
.mirror {
  transform: scaleX(-1);
}

/*have space around image*/
.space-img {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}

@media only screen and (min-width: $screen-md-min) {
  .space-img {
    margin: 0px;
  }
}

/* : to use for the top fixed nav => https://gist.github.com/nicoxxxcox/87b504a98efb6b0ca791b7a6d56319cd */
.affix {
  top: 0;
  right: 0;
  left: 0;
}

//Lydia//

.divider-img {
  background: url(https://www.boulangerie-lumineau.fr/images/charte/divider.png) center no-repeat;
  height: 20px;
  max-width: 400px;
  margin: 10px auto;
}

/* Stylize list with fontawesome unicode => https://fontawesome.com/v4.7.0/icons/ */
ul.list-icon {
  list-style: none;
  padding: 0;
}

ul.list-icon>li {
  padding-left: 1.3rem;
  padding-bottom: 0.3rem;
}

ul.list-icon>li:before {
  content: "\f058";
  /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3rem;
  /* same as padding-left set on li */
  width: 1.3rem;
  /* same as padding-left set on li */
}

//tables
.table td {
  border-top: solid 2px #eee !important;
}

.thead__th {
  text-align: right;
}

@media (max-width: 700px) {
  .thead {
    display: none;
  }

  .table tr {
    display: block;
    margin-bottom: 40px;
  }

  .table td {
    display: block;
    text-align: right;
  }

  .table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}

// Class explications :
// .titre pour nom de la prestation
// .prix pour les tarifs
// le signe euro est g�n�rer automatiquement avec un before
// .des pour les descriptions - d�tails prestations
// creer une carte menu claire//
.table-title {
  font-weight: 500;
  background-color: #fff; // background-color: red;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.table-price {
  font-weight: 800;
  display: inline-block;
  padding: 5px 10px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  width: 60px;

  &::after {
    font-family: "FontAwesome";
    font-weight: 300;
    content: "\f153";
    position: absolute;
    top: 5px;
    left: 55px;
    bottom: 0;
    right: 0;
    z-index: 0;
    background-color: #fff;
  }
}

.list-price {
  list-style: none;
  margin: 3rem auto;
  padding: 0;
  max-width: 110rem;
  border: 1px solid #444;
  padding: 20px;
  background-color: #fff;

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      border-bottom: 1px dashed #444;
      max-width: 1210px;
    }
  }

  li.desc {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      border-bottom: none;
      max-width: 1210px;
    }
  }
}

//blockquotes
blockquote {
  background: url(https://3.bp.blogspot.com/-6Cz5ZZSF770/V1FF0SioZ6I/AAAAAAAAAeU/-rv7t7Q_H7Q0SWb_wgi9n0vxbkOuM1M4wCLcB/s320/bg-quote3a.png) top center no-repeat,
    url(https://3.bp.blogspot.com/-R47112ZnBE4/V1FF0ePq0EI/AAAAAAAAAeY/kazHLrP27ekR_ly_enF0nCVEOiq5DaDvgCLcB/s320/bg-quote3b.png) bottom center no-repeat;
  color: #a5a4a4;
  font-style: italic;
  margin: 15px;
  padding: 15px;
  text-align: center;
  border-left: none;
}

blockquote.block-2 {
  background: #fcfcfc;
  border-left: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  border-right: 1px dashed #ccc;
  border-top: 1px dashed #ccc;
  color: #a5a4a4;
  font-style: italic;
  margin: 15px;
  padding: 15px;
}

blockquote.block-3 {
  font-weight: 300;
  margin: 0px 25px 0px 25px;
  padding: 15px 15px 15px 25px;
  color: #fff;
  border-top: 5px solid #77818d;
  background: #77818d url(https://2.bp.blogspot.com/-efI8eumd0Ow/UdJQYLiab9I/AAAAAAAACP4/ePmgr9wVJew/s16/icon_blockquote.png) no-repeat 20px 25px;
}

.pills-custom {
  padding-left: 0;
  list-style: none;

  >li {
    // @extend .well;
    // @extend .well-sm;
    background: rgba(242, 101, 34, 0.85);
    color: #fff;
    text-align: center;
    border-radius: 15px;
    display: block;
    margin: 10px;
    border-radius: 15px;
    margin: 10px;
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0;

    // @media (min-width: screen-xs-min) {
    //   display: inline-block;
    // }
  }
}

.blockquote-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 20px 10px;
}

.blockquote-3::before {
  content: open-quote;
}

.blockquote-3::after {
  content: close-quote;
}

.blockquote-3::before,
.blockquote-3::after {
  opacity: 0.25;
  padding: 0 10px;
  font-size: 5rem;
}

// Perso Niko

.img-cover {
  position: relative;
  display: block;
  width: 100%;
  height: 30rem;
  object-fit: cover;
}

.caption-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg,
      rgba(black, 0) 20%,
      rgba(black, 0.8) 100%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &--animated {
    background-color: transparent;
    overflow: hidden;
    transition: background-color ease 0.5s;

    .animated-panel {
      height: 40%;
      transition: height ease 0.4s;
    }

    .animated-content {
      opacity: 0;
      position: relative;
      top: 5rem;
      transition: opacity ease 0.4s, top ease 0.4s;
      transition-delay: 0.2s;
    }

    .btn-box {
      position: absolute;
      bottom: -5rem;
      left: 0;
      right: 0;
      @include padding(2rem);
      opacity: 0;
      transition: bottom ease 0.4s, opacity ease 0.5s;
    }

    &:hover {
      background-color: rgba(black, 0.5);

      .animated-panel {
        height: 100%;
      }

      .animated-content {
        opacity: 1;
        top: 0;
      }

      .btn-box {
        bottom: 0;
        opacity: 1;
      }
    }
  }
}

.decal-left {
  @media (min-width: $screen-md-min) {
    margin-left: -20%;
  }
}

@media (min-width: $screen-md-min) {
  .figure__compo-1 {
    position: relative;
    @include padding(1rem);
    height: 40rem;
    max-height: 100%;
    width: 40rem;
    max-width: 100%;

    >img {
      position: absolute;
      object-fit: cover;
    }

    >img:nth-child(1) {
      top: 0;
      left: 0;
      width: 70%;
      height: 55%;
    }

    >img:nth-child(2) {
      bottom: 0;
      right: 0;
      width: 70%;
      height: 55%;
    }
  }
}


.content-slider {
  margin: 30px auto;
  max-width: 1000px;
  box-shadow: $box-shadow;
}

.section-actu {
  margin: 30px auto;
  width: 100%;
  max-width: 1060px;

  @media(min-width: $screen-md-min) {
    padding: 0 30px;
  }
}

.embed-responsive-map {
  padding-bottom: 40rem;
}

.bg-white {
  background-color: white;
}

.d-md-flex {

  @media(min-width: $screen-md-min) {
    display: flex;
  }
}

.p-relative {
  position: relative;
}

.block-address {

  @media(min-width: $screen-md-min) {
    position: absolute;
    top: 3rem;
    right: 3rem;
    width: 25%;
  }
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

/* ----------------- TXT/LABELS/WELL ETC ---------------------- */


.label {
  font-size: 100%;
}

.well {
  background: rgba($brand-primary, 0.85);
  color: #fff;
  text-align: center;

  // a {
  //   filter: invert(100%);
  //   color: $brand-primary;
  // }
}

.jumbotron {
  background: rgba($brand-info, 0.85);
  color: #fff;

  // a {
  //   filter: invert(100%);
  //   color: $brand-info;
  // }
}

.lead {
  color: rgba($brand-success, 0.85);

  // a {
  //   filter: invert(100%);
  //   color: $brand-success;
  // }
}

.main {
	padding-top: 0px;
	min-height: 50vh;
}

.embed-responsive-map {
  padding-bottom: 35rem;
}
