#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    /* a changer si le bg n'est pas blanc */
    z-index: 9999;
    /* makes sure it stays on top */
}

#status {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    /* centre l'animation de chargement horizontalement sur l'�cran */
    top: 50%;
    /* centre l'animation de chargement verticalement � l'�cran */
    background-image: url(../images/charte/preloader.jpg);
    background-repeat: no-repeat;
    background-position: center;
    margin: -100px 0 0 -100px;
    /* largeur et la hauteur divis�e par deux */
}