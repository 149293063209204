/* ==========================================================================
    header-slider-1
    ========================================================================== */

//     .header-slider-1 {
//     @extend .no-pad;
//     position: relative;

//     @media(min-width: $screen-lg-min) {
//         // margin-top: 3rem;
//     }
    
//     .logo-header {
//         @extend .text-center;
//         position: absolute;
//         display: block;
//         top: 0%;
//         left: 10%;
//         right: 10%;

//         // @media(min-width: $screen-sm-min) {
//         //     right: auto;
//         // }
        
//         >img {
//             @extend .img-responsive;
//             display: inline-block;
//             width: 25rem;
//         }
//     }
// }


/* ==========================================================================
	 Animated header
	 ========================================================================== */

	.animated-header {
		background-color: $navbar-default-bg;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		padding: 3rem 0;
		transition: padding ease .3s;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	
		&.affix {
			padding: 1.5rem 0;
			
			.navbar-brand {
	
				img {
					height: 8rem;
				}
			}
		}
	}
	
	.navbar-brand {
		padding: 0 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
	
		img {
			height: 10rem;
			transition: height ease .3s;
			@media (max-width: $screen-xs-max) {
				height: 8rem;
			}
		}
	}
	
	.main {
		// � ajuster en fonction de la hauteur du header
		padding-top: 12rem;
	}
	
	
.header_aside {
	padding: 1rem;
	position: absolute;
	width: 100%;
	z-index: 5;
	top: 9.7rem;
	left: 0;
	background: url(../images/home/lampes.jpg) no-repeat top center #eee;
	background-size: 100% auto;

	@media(min-width: $screen-md-min) {
		height: 500px;
		top: 9.7rem;
		bottom: 0;
		width: 33.33333%;
		padding: 5%;
	}
}

.header_aside--index {

	@media(min-width: $screen-md-min) {
		height: 70vh;
	}

	@media (min-width: $screen-lg-min) {
		height: 70vh;
	}
}

.header_address {
	@extend .hidden-xs;
	@extend .hidden-sm;
	margin: 5rem 1rem 2rem;
	padding-bottom: 3rem;
	border-bottom: solid 2px white;
	font-size: 1.3rem;

	@media(min-width: $screen-lg-min) {
		font-size: 1.4rem;
		line-height: 1.6;
	}
}

.img-header {
	display: block;
	width: 100%;
	height: 250px;
	object-fit: cover;
	margin-top: 9.7rem;

	@media(min-width: $screen-md-min) {
		height: 500px;
	}
}

.header-default{
	position: relative;
	z-index: 0;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(#fff, .6);
		z-index: 2;
	}
}
